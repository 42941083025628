import { useRef, useState } from "react";

function TextToSpeechButton({ lang, text, color = "white", onStart, onFinish, audioOutputDevice }) {
  const [playing, setPlaying] = useState(false)

  const outputRef = useRef(null);
  const handleSubmit = () => {
    const params = `?from=${lang}&to=${lang}&text=${text}`;
    fetch(process.env.REACT_APP_PENMAN_TTS_ENDPOINT + params, {
      method: "POST"
    }).then(response => {
      if (response.status === 200)
        return response.json();
      return Promise.reject({ status: response.status, statusText: response.statusText });
    }).then(async (data) => {
      const newOutput = new Audio("data:audio/mp3;base64," + data.result[lang]);
      if (audioOutputDevice) {
        await newOutput.setSinkId(audioOutputDevice);
        newOutput.volume = 1;
      }
      outputRef.current = newOutput;
      newOutput.onended, newOutput.onpause = () => {
        if (!onFinish) return
        onFinish()
        setPlaying(false)
      }
      newOutput.play().then(() => {
        if (!onStart) return
        onStart()
        setPlaying(true)
      });
    }).catch((error) => {
      console.error(error);
    })
  }

  const play = <span className="ml-1" onClick={handleSubmit}>
    <i className="fas fa-bullhorn" />
  </span>

  const pause = <span className="ml-1" onClick={() => outputRef.current?.pause()}>
    <i className="fas fa-stop-circle" />
  </span>

  return (
    <button className={`tts text-${color}`}>
      {playing ? pause : play}
    </button>
  );
}

export default TextToSpeechButton;
