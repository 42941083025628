import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Fetch from 'i18next-fetch-backend';

const langDetectorOptions = {
  // order and from where user language should be detected
  order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['cookie', 'localStorage'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 60 * 24 * 7,
  cookieDomain: window.location.hostname,
  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { secure: true, sameSite: 'strict' },

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement
}

const backendOptions = {
  // path where resources get loaded from, or a function
  loadPath: process.env.PUBLIC_URL + '/locales/{{lng}}/{{ns}}.json',

  // init option for fetch, for example
  requestOptions: {
    mode: 'cors',
    credentials: 'same-origin',
    cache: 'default',
  }
}

i18n
  .use(Fetch)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    detection: langDetectorOptions,
    fallbackLng: 'en',
    partialBundledLanguages: true,
    react: {
      useSuspense: false,
    },

    ns: ['common'],
    defaultNS: 'common',
    fallbackNS: ['common'],

    backend: backendOptions,

    interpolation: {
      escapeValue: false, // react already safes from xss
      format: function (value, format, lng) {
        const currency = localStorage.getItem('currency')
        if (format === 'int') return parseFloat(value).toLocaleString(lng)
        if (format === 'float2d') return parseFloat(value).toLocaleString(lng, { style: 'currency', currency: currency, minimumFractionDigits: 2, maximumFractionDigits: 2 })
        if (format === 'float0or2d') {
          //check if value is integer or float
          let franctionDigits = value % 1 === 0 ? 0 : 2
          return parseFloat(value).toLocaleString(lng, { style: 'currency', currency: currency, minimumFractionDigits: franctionDigits, maximumFractionDigits: franctionDigits })
        }
        if (format === 'date') return new Date(value).toLocaleString(lng, { year: 'numeric', month: 'short', day: 'numeric' })
        if (format === 'time') return new Date(value).toLocaleString(lng, { hour: 'numeric', minute: 'numeric' })
        return value;
      }
    }
  });
